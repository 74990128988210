<template>
    <div class="tw-min-h-[calc(100dvh-148px)] tw-w-full tw-bg-white tw-rounded tw-overflow-hidden tw-p-5">
        <div class="tw-grid tw-grid-cols-12 tw-gap-5 ">
            <div class="tw-col-span-full lg:tw-col-span-6 tw-flex tw-flex-col tw-gap-2 tw-animate-pulse">
                <div class="tw-h-[550px] tw-w-full  tw-bg-gray-200 tw-p-5  tw-rounded">

                </div>
                <div class="tw-w-full tw-grid tw-grid-cols-12 tw-gap-2">
                    <div class="tw-col-span-2 tw-aspect-square tw-bg-gray-100 tw-rounded"></div>
                    <div class="tw-col-span-2 tw-aspect-square tw-bg-gray-100 tw-rounded"></div>
                    <div class="tw-col-span-2 tw-aspect-square tw-bg-gray-100 tw-rounded"></div>
                </div>
            </div>

            <div class="tw-col-span-full lg:tw-col-span-6 lg:tw-p-5 tw-animate-pulse">
                <div class="tw-w-full tw-h-[50px] tw-bg-gray-100 tw-rounded"></div>
                <div class="tw-w-1/3 tw-h-[30px] tw-bg-gray-50 tw-mt-2 tw-rounded"></div>
                <div class="tw-w-full tw-h-[300px] tw-bg-gray-50 tw-mt-10 tw-rounded"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style></style>