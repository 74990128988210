<template>
  <div class="py-5 px-2 tw-border tw-bg-white tw-w-full tw-rounded-md tw-mb-5">
    <section class="tw-px-3">
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
        <div>
          <div class="tw-flex tw-items-center tw-gap-x-3">
            <h2
              class="tw-text-lg tw-font-medium tw-text-gray-800 darkx:tw-text-white"
            >
              Products
            </h2>

            <span
              class="tw-px-3 tw-py-1 tw-text-xs tw-text-emerald-600 tw-bg-emerald-100 tw-rounded darkx:tw-bg-gray-800 darkx:tw-text-orange-400"
              >{{ 0 }} products</span
            >
          </div>

          <p class="tw-mt-1 tw-text-sm tw-text-gray-500 darkx:tw-text-gray-300">
            List of affiliate products.
          </p>
        </div>

      </div>
    </section>
  </div>
</template>

<script setup>
</script>

<style>

</style>